import util from '../../../../../utils/utils2.js'
export default {
	basic: {
		name: '基本信息',
		des: 'basic',
		header: [{
			title: '城市',
			key: 'city',
			default: true,
			myFocus: true,
			align: 'center',
			minWidth: 140
		}, {
			title: '行政区',
			key: 'district',
			default: true,
			myFocus: true,
			align: 'center',
			minWidth: 140,
			render: (h, params) => {
				return h(
					"div",
					params.row.district ? params.row.district : '--'
				);
			},

		}, {
			title: '土地用途',
			key: 'landUseName',
			default: true,
			myFocus: true,
			align: 'center',
			minWidth: 140
		}, {
			title: '特殊用途',
			key: 'specialApplicationName',
			default: false,
			myFocus: false,
			align: 'center',
			minWidth: 140
		}, {
			title: '交易状态',
			key: 'transactionStateName',
			default: true,
			myFocus: true,
			align: 'center',
			minWidth: 140
		}, {
			title: '建筑面积（㎡）',
			key: 'buildingAreaStr',
			default: true,
			myFocus: true,
			align: 'center',
			minWidth: 140
		}, {
			title: '土地面积（㎡）',
			key: 'landAreaStr',
			default: true,
			myFocus: true,
			align: 'center',
			minWidth: 140
		}, {
			title: '建设用地面积（㎡）',
			key: 'constructionLandAreaStr',
			default: true,
			myFocus: true,
			align: 'center',
			minWidth: 140
		}, {
			title: '容积率',
			key: 'volumeRate',
			default: true,
			myFocus: true,
			align: 'center',
			minWidth: 140
		}, {
			title: '绿化率',
			key: 'greeningRate',
			default: false,
			myFocus: false,
			align: 'center',
			minWidth: 140
		}, {
			title: '宗地编号',
			key: 'landNumber',
			default: false,
			myFocus: false,
			align: 'center',
			minWidth: 140
		}, {
			title: '地块位置',
			key: 'landPosition',
			default: false,
			myFocus: false,
			align: 'center',
			minWidth: 140
		}, ]
	},
	transfer: {
		name: '土地出让',
		des: 'transfer',
		header: [{
			title: '出让方式',
			key: 'transferWayName',
			default: false,
			myFocus: false,
			align: 'center',
			minWidth: 140
		}, {
			title: '出让时间',
			key: 'transferDate',
			default: true,
			myFocus: true,
			align: 'center',
			minWidth: 140,
			render: (h, params) => {
				return h(
					"div",
					util.formatDateFmt(
						new Date(params.row.transferDate),
						"yyyy-MM-dd"
					)
				);
			},
		}, {
			title: '截止时间',
			key: 'expirationDate',
			default: false,
			myFocus: true,
			align: 'center',
			minWidth: 140,
			render: (h, params) => {
				return h(
					"div",
					util.formatDateFmt(
						new Date(params.row.expirationDate),
						"yyyy-MM-dd"
					)
				);
			},
		}, {
			title: '起始价（万元）',
			key: 'startPriceStr',
			default: true,
			myFocus: true,
			align: 'center',
			minWidth: 140
		}, ]
	},
	deal: {
		name: '土地成交',
		des: 'deal',
		header: [{
			title: '受让方',
			key: 'assignee',
			default: true,
			myFocus: true,
			align: 'center',
			minWidth: 240
		}, {
			title: '成交时间',
			key: 'transactionDate',
			default: true,
			myFocus: true,
			align: 'center',
			minWidth: 140,
			render: (h, params) => {
				return h(
					"div",
					util.formatDateFmt(
						new Date(params.row.transactionDate),
						"yyyy-MM-dd"
					)
				);
			},
		}, {
			title: '成交价（万元）',
			key: 'transactionPriceStr',
			default: true,
			myFocus: true,
			align: 'center',
			minWidth: 140
		}, {
			title: '楼面价(元/㎡)',
			key: 'floorPriceStr',
			default: true,
			myFocus: true,
			align: 'center',
			minWidth: 140
		}, {
			title: '溢价率',
			key: 'premiumRate',
			default: true,
			myFocus: true,
			align: 'center',
			minWidth: 140,
			render: (h, params) => {
				return h(
					"div",
					params.row.premiumRate !== '' ? `${(params.row.premiumRate * 100).toFixed(2)}%` : '--'
				);
			},
		}, ]
	}

}




