<template>
	<div class="searchBox">
		<MySearch :searchTemplateList='searchTemplateList' @setSearch='setSearch'></MySearch>
		<div class="otherInput">
			<Form ref="SearchForm" :model="SearchForm" inline label-position='right' :label-width='100'>
				<FormItem label='城市' prop="city">
					<CitySelect :isTransfer="false" :ctiy='SearchForm.city' @selectArea='selectArea' :canSelectProvince='false' :showQuanGuo='false' ref="citySelect">
					</CitySelect>
				</FormItem>
				<FormItem label='出让时间' prop="transferDate">
					<DatePicker style='width: 160px;' type="date"
						@on-change='(date)=>{getDate(date,"transferDate","Start")}' :value='transferDateStart'
						:options="startOptions" placeholder="选择出让开始时间"></DatePicker>
					<span> - </span>
					<DatePicker style='width: 160px;' type="date"
						@on-change='(date)=>{getDate(date,"transferDate","End")}' :value='transferDateEnd'
						:options="transferDateEndOptions" placeholder="选择出让结束时间"></DatePicker>
				</FormItem>
				<FormItem label='成交时间' prop="transactionDate">
					<!-- <DatePicker style='width: 200px;' type="daterange"
						@on-change='(date)=>{getDate(date,"transactionDate")}' :value='transactionDate'
						:options="disOptions" split-panels placeholder="选择成交时间"></DatePicker> -->
					<DatePicker style='width: 160px;' type="date"
						@on-change='(date)=>{getDate(date,"transactionDate","Start")}' :value='transactionDateStart'
						:options="startOptions" placeholder="选择成交开始时间"></DatePicker>
					<span> - </span>
					<DatePicker style='width: 160px;' type="date"
						@on-change='(date)=>{getDate(date,"transactionDate","End")}' :value='transactionDateEnd'
						:options="transactionDateEndOptions" placeholder="选择成交结束时间"></DatePicker>
				</FormItem>
			</Form>
		</div>
		<div :class="['SearchForm',open?'open':'close']">
			<Form ref="SearchForm" :model="SearchForm" inline label-position='right' :label-width='100'>
				<SearchFormItem v-show='districtCodeList.length != 0' ref='district' label='行政区' prop='district'
					:itemList='districtCodeList' :canEdit='false' @selectValue='selectValue'
					@editSelectVal='editSelectVal'>
				</SearchFormItem>
				<SearchFormItem :ref='item.prop' v-for="(item,index) in options" :label='item.label' :prop='item.prop'
					:itemList='item.list' :key='index' :canEdit='item.canEdit' @selectValue='selectValue'
					:unit='item.unit' @editSelectVal='editSelectVal'>
				</SearchFormItem>
			</Form>
		</div>
		<div class="otherInput">
			<Form ref="SearchForm" :model="SearchForm" inline label-position='right' :label-width='100'>
				<FormItem label='地块名称' prop="landName">
					<Input placeholder="请输入地块名称" v-model="SearchForm.landName" />
				</FormItem>
				<FormItem label='受让方' prop="assignee">
					<Input placeholder="请输入受让方" v-model="SearchForm.assignee" />
				</FormItem>
				<FormItem label='宗地编号' prop="landNumber">
					<Input placeholder="请输入宗地编号" v-model="SearchForm.landNumber" />
				</FormItem>
			</Form>
		</div>
		<div :class="['_switch',open?'open':'close']" @click="_switch"></div>

	</div>
</template>

<script>
	import OPTIONS from './options.js'
	import SearchFormItem from './SearchFormItem.vue'
	import MySearch from './MySearch.vue'
	import CitySelect from '../../../components/citySelect/index.vue'

	import {
		land_data_query_district
	} from '../../../../../api/reLand.js'
	import {formatDate, log} from '../../../../../utils/index'
	export default {
		name: 'SearchForm',
		components: {
			SearchFormItem,
			MySearch,
			CitySelect
		},
		props: {
			searchTemplateList: {
				type: Array,
				default: () => {
					return []
				}
			},
		},
		data() {
			return {
				mySearchList: [],
				options: [],
				SearchForm: {
					city: {
						"center": {
							"lat": "23.135336",
							"lng": "113.271431"
						},
						"city": "广州",
						"cityCode": "4401",
						"cityName": "广州市",
						"isProvinceCapital": 1,
						"lat": "23.135336",
						"level": 1,
						"lng": "113.271431",
						"zoom": 13
					},
					transferDateStart: '',
					transferDateEnd: '',
					transactionDateStart: '',
					transactionDateEnd: '',
					landName: '',
					assignee: '',
					landNumber: '',
				},
				transferDateStart: '',
				transferDateEnd: '',
				transactionDateStart: '',
				transactionDateEnd: '',

				open: false,
				//多选选项
				districtCodeList: [],
				// landUseCodeList:[],
				// transactionStateCodeList:[],
				startOptions: {
					disabledDate(date) {
						return date && date.valueOf() < new Date('2011-1-1') || date.valueOf() > Date.now()
					}
				},
				transferDateEndOptions: {
					disabledDate: (date) => {
						return date && date.valueOf() < new Date('2011-1-1') || date.valueOf() > Date.now()
					}
				},
				transactionDateEndOptions: {
					disabledDate: (date) => {
						return date && date.valueOf() < new Date('2011-1-1') || date.valueOf() > Date.now()
					}
				},
			}
		},
		computed:{
			defaultTransferDateStart(){
				return this.transferDateStart?this.transferDateStart:formatDate(new Date().setDate(new Date().getDate()-60))
			},
			defaultTransferDateEnd(){
				return this.transferDateEnd?this.transferDateEnd:formatDate(new Date().setDate(new Date().getDate()))
			}
		},
		watch: {
			'SearchForm.city': {
				handler(val) {
					this.getDistrict(val)
				},
				deep: true,
				immediate: true,
			}
		},
		mounted() {
			this.init()
		},
		methods: {
			init() {
				if(this.$route){
					if(this.$route.query.searchVal) {
						this.$set(this.SearchForm,'landName',this.$route.query.searchVal)
					}
					if(this.$route.query.searchVal2) {
						this.$set(this.SearchForm,'landName',this.$route.query.searchVal2)
					}
				}

				this.options = OPTIONS
				for (let key in this.options) {
					this.setITem(this.options[key])
				}
				this.getDate(this.defaultTransferDateStart,'transferDate','Start')
				this.getDate(this.defaultTransferDateEnd,'transferDate','End')
			},
			setITem(obj) {
				this.$set(this.SearchForm, `${obj.prop}`, [])
			},
			getDate(value, item, opt) {
				this.SearchForm[`${item}${opt}`] = value
				this[`${item}${opt}`] = value
				if (opt == 'Start') {
					this[`${item}End`] = ''
					this.SearchForm[`${item}End`] = ''
					this[`${item}EndOptions`] = {
						disabledDate: (date) => {
							//目前的时间戳
							let disavleEndDateValue = Date.now()
							//距离开始时间3年的时间戳
							// let threeYears = new Date(this.SearchForm[`${item}Start`]).valueOf() + 3 * 365 * 24 *
							// 	60 *
							// 	60 * 1000
							// //根据大小，设置禁用的最大值
							// if (threeYears < disavleEndDateValue) {
							// 	disavleEndDateValue = threeYears
							// }
							return date && date.valueOf() < new Date(this.SearchForm[`${item}Start`]).valueOf() -
								1 * 24 * 60 * 60 * 1000 ||
								date
								.valueOf() >
								disavleEndDateValue
						}
					}
				}
			},
			reSetForm() {
				this.$refs.SearchForm.resetFields()
				this.transferDateStart = ''
				this.SearchForm.transferDateStart = ''
				this.transferDateEnd = ''
				this.SearchForm.transferDateEnd = ''
				this.transactionDateStart = ''
				this.SearchForm.transactionDateStart = ''
				this.transactionDateEnd = ''
				this.SearchForm.transactionDateEnd = ''

				for (let key in this.$refs) {
					if (key.indexOf('Code') > -1) {
						this.$refs[key][0].reSet()
					}
				}
				this.$refs.district.reSet()
			},
			_switch() {
				this.open = !this.open
			},
			selectValue(prop, data) {
				this.SearchForm[prop] = data
			},
			editSelectVal(res) {
				for (let key in res) {
					this.SearchForm[key] = res[key]
				}
			},
			//设置选择条件
			setSearch(res) {
				//重置
				this.reSetForm()
				this.$nextTick(() => {
					if (res) {
						//设置数据
						for (let key in this.$refs) {
							if (key.indexOf('Code') > -1) {
								this.$refs[key][0].setValue(res[key])
								let noCodeKey = key.replace('Code','')
								if (res[`${noCodeKey}Start`]) {
									this.$refs[key][0].setInputValue('start', res[`${noCodeKey}Start`])
								}
								if (res[`${noCodeKey}End`]) {
									this.$refs[key][0].setInputValue('end', res[`${noCodeKey}End`])
								}
							}
						}
						//设置数据
						for (let key in this.SearchForm) {
							this.SearchForm[key] = res[key]
						}
						//设置出让时间
						if (res.transferDateStart && res.transferDateEnd) {
							this.transferDateStart = res.transferDateStart
							this.SearchForm.transferDateStart = res.transferDateStart
							this.transferDateEnd = res.transferDateEnd
							this.SearchForm.transferDateEnd = res.transferDateEnd
						}
						//设置成交时间
						if (res.transactionDateStart && res.transactionDateEnd) {
							this.transactionDateStart = res.transactionDateStart
							this.SearchForm.transactionDateStart = res.transactionDateStart
							this.transactionDateEnd = res.transactionDateEnd
							this.SearchForm.transactionDateEnd = res.transactionDateEnd

						}
						//设置行政区
						setTimeout(() => {
							if (res.hasOwnProperty('district') && res.district.length != 0) {
								this.$refs.district.setValue(res.district)
							}
						}, 500)
					}
				})

			},
			selectArea(res) {
				//选择区域时重置选择模版
				this.$parent.getSearchTemplate()
				this.SearchForm.city = res
			},
			//获取行政区
			async getDistrict({
				cityCode
			}) {
				if (this.$refs.district) {
					this.$refs.district.reSet()
				}
				let data = await land_data_query_district({
					cityCode: cityCode
				})
				let arr = []
				data.forEach(item => {
					let obj = {}
					obj.name = item.districtName
					obj.code = item.district
					arr.push(obj)
				})
				this.districtCodeList = arr
			},
		},
	}
</script>

<style scoped lang="scss">
	.SearchForm {
		position: relative;
		overflow: hidden;

		&.close {
			height: 230px;
		}

		&.open {
			height: auto;
		}
	}

	._switch {
		position: relative;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		width: 70px;
		height: 20px;
		background-repeat: no-repeat;
		background-position: center;
		cursor: pointer;
		margin-bottom: 40px;

		&.close {
			background-image: url(../../../../../assets/image/land/close.png);
		}

		&.open {
			background-image: url(../../../../../assets/image/land/open.png);
		}
	}

	.otherInput {
		margin-top: 20px;
	}
</style>
