<template>
	<div class="SearchTab">
		<SearchForm ref='SearchFormValue' :searchTemplateList='searchTemplateList'></SearchForm>
		<div class="btnBox">
			<Button class="btn" @click="reset">重置</Button>
			<Button class="btn searchBtnLog" style="margin:0 16px" data-id="1" type="primary" @click="search">查询</Button>
			<!-- <Button class="btn" @click="saveSearch">保存查询条件</Button> -->
			<ComfirmButton ref="ComfirmButton" size='large' type='primary' @comfirm='saveSearch' info='保存查询条件'>
				<div slot="content">
					<div class="sumBox">
						<Form ref="addSearchGroup" :model="addSearchGroup" inline label-position='right'
							:label-width='100'>
							<FormItem label='查询组名称' prop="templateName">
								<Input maxlength='10' show-word-limit placeholder="请输入查询组名称"
									v-model="addSearchGroup.templateName" />
							</FormItem>
						</Form>
					</div>
				</div>
			</ComfirmButton>
		</div>
		<!-- <div class="gap"></div> -->
		<SearchResults ref='SearchResults' :showTra='showTra' :landData='landData' :total='total' @pageChange = 'pageChange' @pageSizeChange = 'pageSizeChange'></SearchResults>

	</div>
</template>

<script>
	import ComfirmButton from '../../../../../components/Comfirm.vue'
	import SearchForm from './SearchForm.vue'
	import SearchResults from './SearchResults.vue'
	import {
		land_data_pageList,
		land_data_is_del_template,
		land_data_query_template
	} from '../../../../../api/reLand.js'
import { log } from '../../../../../utils'

	export default {
		components: {
			SearchForm,
			SearchResults,
			ComfirmButton
		},
		name: 'SearchTab',
		data() {
			return {
				showTra: false,
				landData: [],
				addSearchGroup: {
					templateName: '',
					isDel: false,
				},
				//保存模版
				searchTemplateList: [],
				pageSize:10,
				current:1,
				total:10
			}
		},
		mounted() {
			this.init()
		},
		activated(){
			// this.search()
		},
		methods: {
			init() {
				this.getSearchTemplate()
				this.search()
			},
			async search(e) {
				let params = JSON.parse(JSON.stringify(this.$refs.SearchFormValue.SearchForm))
				params.cityCode = this.$refs.SearchFormValue.SearchForm.city.cityCode
				params.pageSize = this.pageSize
				params.current = e && e.type == 'click' ? 1 : this.current
				let data = await land_data_pageList(params)
				if (data.records && data.total != 0) {
					this.landData = data.records
					this.total = data.total
					if(e && e.type == 'click'){
						this.$msg.success({
							text: `共找到${data.total}条数据`
						})
					}
					//控制指标
					this.showTra = false
					if (this.$refs.SearchFormValue.SearchForm.hasOwnProperty('transactionStateCode')) {
						let transactionStateCode = this.$refs.SearchFormValue.SearchForm.transactionStateCode
						transactionStateCode.forEach(item => {
							if (item == 2) {
								this.showTra = true
							}
						})
					}
					this.$nextTick(() => {
						setTimeout(() => {
							this.$refs.SearchResults.$refs.IndexPanel.sure()
						}, 0)
					})
				} else {
					this.$msg.error({
						text: "暂无数据"
					})
					this.$refs.SearchResults.$refs.IndexPanel.sure()
					this.landData = data.records
				}
			},
			async getSearchTemplate() {
				let data = await land_data_query_template({
					route: this.$route.path
				})
				data.forEach(item => {
					item.checked = false
				})
				this.searchTemplateList = data
			},
			async saveSearch() {
				if (this.addSearchGroup.templateName != '') {
					let params = {
						templateName: this.addSearchGroup.templateName,
						isDel: this.addSearchGroup.isDel,
						params: JSON.stringify(this.$refs.SearchFormValue.SearchForm),
						route: this.$route.path
					}
					land_data_is_del_template(params).then(() => {
						this.$msg.success({
							text: "保存成功"
						})
						this.getSearchTemplate()
					}).catch(err => {
						// this.$msg.success({
						// 	text: err.data
						// })
					})
				} else {
					this.$msg.error({
						text: "请输入查询组名称"
					})
				}
			},
			reset() {
				this.getSearchTemplate()
				this.$refs.SearchFormValue.reSetForm()
			},
			pageChange(e) {
				this.current = e
				this.search()
			},
			pageSizeChange(e) {
				this.pageSize = e
				this.search()
			}
		},
	}
</script>

<style scoped lang="scss">
	.btnBox {
		position: relative;
		left: 50%;
		transform: translateX(-50%);
		text-align: center;

		.btn {
			margin: 5px 0;
			width: 140px;
			height: 40px;
		}
	}

	.sumBox {
		margin-top: 50px;
		text-align: center;
	}

	// .gap {
	// 	margin: 30px;
	// 	width: 120%;
	// 	height: 30px;
	// 	position: relative;
	// 	left: 50%;
	// 	transform: translateX(-50%);
	// 	background-color: #f0f3f8;
	// }
</style>
