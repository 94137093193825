export default {
	// districtCodeOption: {
	// 	label: '行政区',
	// 	prop: 'districtCode',
	// 	canEdit:false,
	// 	unit:'',
	// 	list: [{
	// 		name: '天河',
	// 		code: '001'
	// 	}, {
	// 		name: '海珠',
	// 		code: '002'
	// 	}, {
	// 		name: '越秀',
	// 		code: '003'
	// 	}, {
	// 		name: '荔湾',
	// 		code: '004'
	// 	}, {
	// 		name: '番禺',
	// 		code: '005'
	// 	}, {
	// 		name: '白云',
	// 		code: '006'
	// 	}]
	// },
	landUseCodeOption: {
		label: '土地用途',
		prop: 'landUseCode',
		canEdit:false,
		unit:'',
		list: [{
			name: '住宅',
			code: 'USE_RES'
		}, {
			name: '商服',
			code: 'USE_COMS'
		}, {
			name: '工业',
			code: 'USE_IND'
		}, {
			name: '其他',
			code: 'USE_OTHER'
		}, ]
	},
	transactionStateCodeOption: {
		label: '交易状态',
		prop: 'transactionStateCode',
		canEdit:false,
		unit:'',
		list: [{
			name: '出让中',
			code: 0
		}, {
			name: '已成交',
			code: 2
		}, {
			name: '流拍',
			code: 1
		}, ]
	},
	floorPriceCodeOption: {
		label: '楼面价',
		prop: 'floorPriceCode',
		canEdit:true,
		unit:'元/㎡',
		list: [{
			name: '0-1000元/㎡',
			code: '0_1000'
		}, {
			name: '1000-5000元/㎡',
			code: '1000_5000'
		}, {
			name: '5000-10000元/㎡',
			code: '5000_10000'
		},{
			name: '10000-20000元/㎡',
			code: '10000_20000'
		},{
			name: '20000-30000元/㎡',
			code: '20000_30000'
		},{
			name: '30000元/㎡以上',
			code: '30000_UP'
		}, ]
	},
	transactionPriceCodeOption: {
		label: '成交价',
		prop: 'transactionPriceCode',
		canEdit:true,
		unit:'万元',
		list: [{
			name: '0-1000万元',
			code: '0_1_KW'
		}, {
			name: '1000-5000万元',
			code: '1_5_KW'
		}, {
			name: '5000-10000万元',
			code: '5_10_KW'
		},{
			name: '10000-50000万元',
			code: '10_50_KW'
		},{
			name: '50000-100000万元',
			code: '50_100_KW'
		},{
			name: '100000万元-300000万元',
			code: '100_300_KW'
		},{
			name: '300000万元以上',
			code: '300_UP_KW'
		}, ]
	},
	landAreaCodeOption: {
		label: '土地面积',
		prop: 'landAreaCode',
		canEdit:true,
		unit:'万㎡',
		list: [{
			name: '1万㎡以下',
			code: '0_1_W'
		}, {
			name: '1-2万㎡',
			code: '1_2_W'
		}, {
			name: '2-5万㎡',
			code: '2_5_W'
		},{
			name: '5-10万㎡',
			code: '5_10_W'
		},{
			name: '10万㎡以上',
			code: '10_UP_W'
		},]
	},
	buildingAreaCodeOption: {
		label: '建筑面积',
		prop: 'buildingAreaCode',
		canEdit:true,
		unit:'万㎡',
		list: [{
			name: '0.5万㎡以下',
			code: '0_0.5_W'
		}, {
			name: '0.5-1万㎡',
			code: '0.5_1_W'
		}, {
			name: '1-5万㎡',
			code: '1_5_W'
		},{
			name: '5-10万㎡',
			code: '5_10_W'
		},{
			name: '10-15万㎡',
			code: '10_15_W'
		},{
			name: '15-20万㎡',
			code: '15_20_W'
		},{
			name: '20万㎡以上',
			code: '20_UP_W'
		},]
	},
	transferWayCodeOption: {
		label: '出让方式',
		prop: 'transferWayCode',
		canEdit:false,
		unit:'',
		list: [{
			name: '挂牌',
			code: '0'
		}, {
			name: '拍卖',
			code: '1'
		}, {
			name: '招标',
			code: '2'
		},{
			name: '公开公告',
			code: '3'
		},]
	},
}
