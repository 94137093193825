<template>
	<Dropdown trigger="custom" :visible="visible" placement='bottom-start'>
		<Button class="btn" type="default" @click="handleOpen">
			指标
			<Icon type="md-arrow-dropdown" />
		</Button>
		<div slot="list">
			<div class="selectBox">
				<div class="close" @click="handleClose">X</div>
				<div class="indexBox">
					<div :key='index' v-for="(item,index) in HeaderOptions" class="checkGroup">
						<div>
							<div class="title">
								{{item.name}}
							</div>
							<PanelItem @selectedItem='selectedItem' :ref='item.des' :prop='item.des'
								:itemList='item.header'></PanelItem>
						</div>
					</div>
				</div>
				<div class="btnBox">
					<Button class="btn" type="default" @click="reSet">清空</Button>
					<Button style="margin-left: 40px;" class="btn" type="primary" @click="sure">确定</Button>
				</div>
			</div>
		</div>
	</Dropdown>
</template>

<script>
	import PanelItem from './panelItem.vue'
	export default {
		name: 'IndexPanel',
		components: {
			PanelItem
		},
		props: {
			headerOptions: {
				type: Object,
				default: () => {
					return {}
				}
			},
		},
		watch: {
			headerOptions: {
				handler(val) {
					this.HeaderOptions = val
				},
				deep: true,
				immediate: true
			}
		},
		data() {
			return {
				HeaderOptions: {},
				visible: false,
				selectedHeader: {},
				once: true,
			}
		},
		methods: {
			handleOpen() {
				this.visible = !this.visible
			},
			handleClose() {
				this.visible = false
			},
			sureHeader() {
				if (this.selectedHeader.hasOwnProperty('basic') || this.selectedHeader.hasOwnProperty('transfer') || this
					.selectedHeader.hasOwnProperty('deal')) {
					if (this.selectedHeader.basic.length == 0 && this.selectedHeader.transfer.length == 0 && this
						.selectedHeader.deal.length == 0) {
						this.$msg.error({
							text: "请先选择指标"
						})
					} else {
						this.handleClose()
						this.$emit('sureHeader', this.selectedHeader)
					}
				}else{
					this.$msg.error({
						text: "请先选择指标"
					})
				}

			},
			selectedItem(prop, res) {
				this.selectedHeader[prop] = res
			},
			sure() {
				this.sureHeader()
			},
			reSet() {
				this.selectedHeader = {}
				for (let key in this.$refs) {
					if (this.$refs[key].length) {
						this.$refs[key][0].reSet()
					}
				}
			}
		},
	}
</script>

<style scoped lang="scss">
	.btn {
		width: 140px;
	}

	.selectBox {
		position: relative;
	}

	/deep/.indexBox {
		width: 800px;
		height: 500px;
		padding: 30px 70px;
		overflow: auto;

		.bigTitle {
			font-size: 18px;
			font-family: Source Han Sans CN, Source Han Sans CN-Medium;
			font-weight: 500;
			text-align: left;
			color: #333333;
			line-height: 36px;


		}

		.checkGroup {
			margin-top: 30px;

			.title {
				position: relative;
				font-size: 16px;
				font-family: Source Han Sans CN, Source Han Sans CN-Regular;
				font-weight: 400;
				text-align: left;
				color: #333333;
				margin-bottom: 20px;

				&::before {
					content: '';
					position: absolute;
					width: 4px;
					height: 24px;
					top: 0px;
					left: -10px;
					background-color: #00B6FF;
				}
			}

			.checkIndex {
				display: inline-block;
				width: 30%;
				margin-top: 10px;

				.columnDescClass {
					height: 40px;
					line-height: 40px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}
	}

	.btnBox {
		width: 800px;
		position: absolute;
		bottom: 30px;
		text-align: center;

		.btn {
			width: 140px;
			height: 40px;
		}
	}

	.close {
		cursor: pointer;
		position: absolute;
		top: 10px;
		right: 10px;
		width: 20px;
		height: 20px;
		font-size: 16px;
		text-align: center;
		line-height: 20px;
	}
</style>
