<template>
	<div class="CityListItem">
		<div :class="[canSelectProvince?['_item','_label']:['_itemNo','_labelNo'],ListObj.type == 'hot'?'hot':'']" @click.stop="selectProvince">
			{{`${ListObj.area}:`}}
		</div>
		<div :class="['_item','_content',ListObj.type == 'hot'?'hot':''] ">
			<div :key="index" v-for="(item,index) in cityList" class="item" @click="selectCity(item)">{{item.cityName}}
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'CityListItem',
		props: {
			ListObj: {
				type: Object,
				default: () => {
					return {}
				}
			},
			canClick:{
				type:Boolean,
				default:()=>{
					return true
				}
			},
			canSelectProvince:{
				type:Boolean,
				default:()=>{
					return true
				}
			}
		},
		watch: {
			ListObj: {
				handler(val) {
					this.cityList = val.list
				},
				deep:true,
				immediate: true			
			}
		},
		data() {
			return {
				cityList: []
			}
		},
		methods: {
			selectCity(item) {
				this.$emit('selectArea', JSON.parse(JSON.stringify(item)),'city')
			},
			selectProvince(){			
				let obj =  this.cityList.filter(item => {
					return item.isProvinceCapital == 1
				})[0]
				if(obj){
					let OBJ = JSON.parse(JSON.stringify(obj))
					OBJ.zoom = this.ListObj.zoom
					OBJ.city = this.ListObj.provinceName
					OBJ.cityName = this.ListObj.provinceName
					this.$emit('selectArea', OBJ ,'province')
				}			
			}
		},
	}
</script>

<style scoped lang="scss">
	.CityListItem {
		font-size: 0px;
		margin-top: 20px;
		min-width: 560px;

		._item {
			display: inline-block;
			vertical-align: top;
			font-size: 16px;
			&:hover{
					color: #1EAEF5;
				}
		}
		._itemNo {
			display: inline-block;
			vertical-align: top;
			font-size: 16px;
		}

		._label {
			color: #333333;
			width: 60px;
			text-align: right;
			cursor: pointer;

			&.hot {
				color: #999999 !important;
				cursor: default !important;
			}
		}
		._labelNo {
			color: #333333;
			width: 60px;
			text-align: right;

			&.hot {
				color: #999999 !important;
				cursor: default !important;
			}
		}

		._content {
			margin-left: 10px;
			width: 480px;

			.item {
				display: inline-block;
				margin: 0 10px;
				cursor: pointer;
				color: #999999;
				height: 24px;
				&:hover{
					color: #1EAEF5;
				}
			}

			&.hot {
				.item {
					color: #1EAEF5 !important;
				}
			}
		}
	}
</style>
