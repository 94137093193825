<template>
	<CheckboxGroup style='display: inline-block;' v-model="SelectedCode"
		@on-change="(data)=>{checkAllGroupChange(data)}">
		<Checkbox :indeterminate="indeterminate" :value="checkAll" @click.prevent.native="handleCheckAll()">全选
		</Checkbox>
		<Checkbox :key='index' v-for="(item,index) in itemList" :label="item.key">{{item.title}}</Checkbox>
	</CheckboxGroup>
</template>

<script>
	export default {
		name: 'panelItem',
		data() {
			return {
				SelectedCode: [],
				indeterminate: false,
				checkAll: false,
			}
		},
		props: {
			itemList: {
				type: Array,
				default: () => {
					return []
				}
			},
			prop:{
				type:String,
				default:''
			}
		},
		mounted(){
			this.SelectedCode = []
			this.itemList.forEach(item => {
				if(item.default){
					this.SelectedCode.push(item.key)
				}
			})
			this.checkAllGroupChange(this.SelectedCode)
		},
		methods: {
			//全选
			handleCheckAll() {
				if (this.indeterminate) {
					this.checkAll = false;
				} else {
					this.checkAll = !this.checkAll;
				}
				this.indeterminate = false;
				if (this.checkAll) {
					this.SelectedCode = this.itemList.map(item => {
						return item.key
					})
				} else {
					this.SelectedCode = [];
				}
				this.checkAllGroupChange(this.SelectedCode)
			},
			//选择时触发
			checkAllGroupChange(data) {
				//控制全选按钮的样式
				if (data.length === this.itemList.length) {
					this.indeterminate = false;
					this.checkAll = true;
				} else if (data.length > 0) {
					this.indeterminate = true;
					this.checkAll = false;
				} else {
					this.indeterminate = false;
					this.checkAll = false;
				}
				this.getItem(data)
			},
			getItem(arr) {
				let Arr = []
				arr.forEach(item => {
					let obj = this.itemList.filter(_item => {
						if(_item.key == item){
							return _item
						}
					})
					Arr.push(obj[0])
				})
				this.$emit('selectedItem',this.prop,Arr)
			},
			//清空
			reSet() {
				this.SelectedCode = []
				this.indeterminate = false
				this.checkAll = false
			}
		},
	}
</script>

<style scoped>
/deep/.ivu-checkbox-wrapper{
	margin-right: 20px;
	margin-top: 10px;
}
</style>
