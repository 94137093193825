<template>
  <div class="listSearchBox">
    <div class="listSearch">
      <div class="btnBox">
        <div
          @click="btn = 0"
          :class="['btn', 'btn2', btn == 0 ? 'active' : '']"
        >
          列表搜索
        </div>
        <div
          @click="btn = 1"
          style="margin-left: -1px"
          :class="['btn', 'btn1', btn == 1 ? 'active' : '']"
        >
          我的关注
        </div>
        <div class="listDataBox" v-show="btn == 0 ? true : false" ref="main">
          <div class="listData">地块数据：2011-01-01~{{ now }}</div>
          <Icon @click="handleClick" type="ios-alert-outline" size="24" />
        </div>
      </div>
      <div v-show="showInfoWindow" class="infoWindow">
        <p class="close" @click.stop="handleClick">X</p>
        <div class="title">数据说明</div>
        <div class="text">
          <p v-for="(item, index) in description" :key="index">
            {{ item.text }}
          </p>
        </div>
      </div>
      <div class="contentBox">
        <keep-alive>
          <component :is="componentTag"></component>
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
import SearchTab from "./SearchTab/index.vue";
import MyFocus from "./MyFocus.vue";
import { formatDate } from "../../../../utils";
import dataReports from "@/mixins/dataReports";
export default {
  name: "listSearch",
  components: {
    SearchTab,
    MyFocus,
  },
  mixins: [dataReports],
  computed: {
    now() {
      return formatDate(new Date());
    },
  },
  watch: {
    btn(val) {
      if (val == 0) {
        this.componentTag = "SearchTab";
      } else if (val == 1) {
        this.componentTag = "MyFocus";
      }
    },
  },
  data() {
    return {
      btn: 0,
      componentTag: "SearchTab",
      showInfoWindow: false,
      description: [
        {
          text: "1、城市范围：当前土地数据涵盖全国一、二线城市共60个城市， 其他城市数据正在建设中。",
        },
        { text: "2、时间范围：土地数据可查询的时间范围为2011-01-01至今。" },
      ],
    };
  },
  mounted() {
    document.addEventListener("click", this.bodyCloseMenuse);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.bodyCloseMenuse);
  },
  methods: {
    handleClick() {
      this.showInfoWindow = !this.showInfoWindow;
    },
    bodyCloseMenuse(e) {
      const self = this;
      if (this.$refs.main && !this.$refs.main.contains(e.target)) {
        if (self.showInfoWindow === true) {
          self.showInfoWindow = false;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.listSearchBox {
  padding: 20px;
}
.listSearch {
  padding: 30px 50px;
  background-color: #ffffff;
  border-radius: 8px;
  position: relative;
}
.btnBox {
  height: 40px;
  box-sizing: content-box;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba($color: #333333, $alpha: 0.3);
  .btn {
    display: inline-block;
    width: 120px;
    height: 40px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% auto;
    font-size: 18px;
    text-align: center;
    line-height: 40px;
    border: 1px solid #00b6ff;
    color: #00b6ff;
    background-color: rgba($color: #cccccc, $alpha: 0.05);
    cursor: pointer;

    &.active {
      color: #ffffff;
      background-color: rgba($color: #00b6ff, $alpha: 1);
    }

    &.btn1 {
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
    }

    &.btn2 {
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
    }
  }
}
.listDataBox {
  box-sizing: content-box;
  display: inline-block;
  .listData {
    display: inline-block;
    color: gray;
    padding: 10px;
  }
  i {
    cursor: pointer;
  }
}
.infoWindow {
  position: absolute;
  width: 430px;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  top: 70px;
  left: 535px;
  z-index: 999;
  font-size: 14px;
  padding: 16px;
  text-align: left;
  border-radius: 8px;
  .title {
    text-align: center;
    font-size: 16px;
    font-family: Source Han Sans CN, Source Han Sans CN-Medium;
    font-weight: 500;
    color: #999999;
    line-height: 20px;
  }
  .text {
    margin: 10px 4px;
    font-size: 14px;
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    font-weight: 400;
    text-align: left;
    color: #333333;
    line-height: 20px;
    p {
      margin: 10px 0;
    }
  }
}
.close {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #333333;
  cursor: pointer;
  font-size: 14px;
}
</style>
