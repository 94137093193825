<template>
	<div class="MyFocus">
		<Table style="margin-top: 30px;" :columns="headers" no-data-text='暂无关注地块' :data="data" disabled-hover>
			<template slot-scope="{ row }" slot="action">
				<span :class="['start',row.isAttention?'active':'']" @click="showComfirm(row)"
					:title="row.isAttention?'取消关注':'关注地块'"></span>
			</template>
			<template slot-scope="{ row }" slot="landName">
				<Button type="text" @click="goLandDetail(row)">
					<span
						style="width: 160px;display:inline-block;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
						:title="row.landName">{{row.landName}}</span>
				</Button>
			</template>
		</Table>
		<ComfirmButton :customerStyle='customerStyle' ref='ComfirmButton' size='small' @comfirm='attention()' info=''>
			<div slot="content">
				<div class="nomalBox">
					<p class="text">{{row.isAttention ?'确认取消关注':'确认关注' }}</p>
				</div>
			</div>
		</ComfirmButton>


	</div>
</template>

<script>
	import Headers from './SearchTab/headerOptions.js'
	import ComfirmButton from '../../../../components/Comfirm.vue'
	import {
		land_data_favorite_group_info,
		land_data_is_cancel_favorite,
		land_data_details
	} from '../../../../api/reLand.js'
	export default {
		name: 'MyFocus',
		components: {
			ComfirmButton
		},
		data() {
			return {
				headers: [],
				data: [],
				row: {},
				customerStyle: {
					'opacity': 0
				},
			}
		},
		activated() {
			this.init()
		},
		methods: {
			init() {
				this.setHeader()
				this.getFavoriteData()
			},
			//获取列表数据
			async getFavoriteData() {
				let data = await land_data_favorite_group_info()
				this.data = data
			},
			//设置表头
			setHeader() {
				this.headers = [{
					title: '操作',
					slot: 'action',
					align: 'center',
					minWidth: 200
				}, {
					title: '地块名称',
					key: 'landName',
					align: 'center',
					slot: 'landName',
					minWidth: 200
				}, ]
				for (let key in Headers) {
					this.headers = this.headers.concat(Headers[key].header.filter(item => {
						return item.myFocus == true
					}))
				}
			},
			//关注or取消
			async attention(row) {
				this.row.isCancel = this.row.isAttention
				this.row.isAttention = !this.row.isAttention
				let data = await land_data_is_cancel_favorite(this.row)
				if (data) {
					this.$msg.success({
						text: this.isAttention ? '关注成功' : '取消关注成功'
					})
					this.getFavoriteData()
				}
			},
			showComfirm(row) {
				this.row = row
				this.$refs.ComfirmButton.toggle()
			},
			//跳转地块详情
			goLandDetail(row) {
				let {
					landRelationKey,
					landPosition,
					transactionStateCode
				} = row
				this.getDetail(landRelationKey, transactionStateCode, landPosition)

			},
			getDetail(landRelationKey, transactionStateCode, landPosition) {
				land_data_details({
					landRelationKey: landRelationKey,
					transactionStateCode: transactionStateCode
				}).then(res => {
					this.$router.push({
						path: '/Insight/land/landDetail',
						query: {
							landRelationKey: landRelationKey,
							landPosition: landPosition,
							transactionStateCode: transactionStateCode,
						}
					})
				}).catch(err => {
					this.$msg.error({
						text: err.data
					})
				})
			},
		},
	}
</script>

<style scoped lang="scss">
	.start {
		cursor: pointer;
		display: inline-block;
		width: 20px;
		height: 20px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: auto;
		background-image: url(../../../../assets/image/land/start.png);

		&.active {
			background-image: url(../../../../assets/image/land/start_active.png);
		}
	}

	.text {
		text-align: center;
		font-size: 18px;
		font-weight: 500;
		padding-top: 50px;
		padding-bottom: 30px;
	}
</style>
