<template>
	<div class="FormItem">
		<FormItem :label='label' :prop="prop">
			<CheckboxGroup style='display: inline-block;' v-model="SelectedCode"
				@on-change="(data)=>{checkAllGroupChange(data)}">
				<Checkbox :indeterminate="indeterminate" :value="checkAll" @click.prevent.native="handleCheckAll()">全选
				</Checkbox>
				<Checkbox :key='index' v-for="(item,index) in itemList" :label="item.code">{{item.name}}</Checkbox>
			</CheckboxGroup>
			<Checkbox :value="editSelect" :disabled='canSelect' v-if='canEdit' @on-change='selectEdit'>
				<Input type="number" style="width: 80px;" v-model="start" @on-blur='inputValue' />
				<span style="margin: 0 5px;">-</span>
				<Input type="number" style="width: 80px;" v-model="end" @on-blur='inputValue' />
				<span style="margin: 0 5px;">{{unit}}</span>
			</Checkbox>
		</FormItem>
		<br>
	</div>
</template>

<script>
	export default {
		name: 'SearchFormItem',
		props: {
			label: {
				type: String,
				default: ''
			},
			prop: {
				type: String,
				default: ''
			},
			itemList: {
				type: Array,
				default: () => {
					return []
				}
			},
			canEdit: {
				type: Boolean,
				default: false
			},
			unit: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				checkAll: false,
				indeterminate: false,
				SelectedCode: [],
				start: '',
				end: '',
				editSelect: false,
			}
		},
		computed: {
			canSelect() {
				if (this.start && this.end) {
					if (this.start * 1 > this.end * 1) {
						return true
					} else {
						return false
					}
				} else {
					return true
				}
			},
		},
		methods: {
			//全选
			handleCheckAll() {
				if (this.indeterminate) {
					this.checkAll = false;
				} else {
					this.checkAll = !this.checkAll;
				}
				this.indeterminate = false;
				if (this.checkAll) {
					this.SelectedCode = this.itemList.map(item => {
						return item.code
					})
				} else {
					this.SelectedCode = [];
				}
				this.checkAllGroupChange(this.SelectedCode)
			},
			//选择时触发
			checkAllGroupChange(data) {
				//控制全选按钮的样式
				if (data.length === this.itemList.length) {
					this.indeterminate = false;
					this.checkAll = true;
				} else if (data.length > 0) {
					this.indeterminate = true;
					this.checkAll = false;
				} else {
					this.indeterminate = false;
					this.checkAll = false;
				}
				//把选择结果返回父元素
				this.$emit('selectValue', this.prop, this.SelectedCode)
			},
			inputValue() {
				let key = this.prop.replace('Code', '')
				if (this.start && this.end) {
					this.editSelect = true
				} else {
					this.editSelect = false
				}
				if (this.editSelect) {
					if (this.start && this.end) {
						if (this.start * 1 > this.end * 1) {
							this.$msg.error({
								text: "请输入正确的数值范围"
							})
							this.editSelect = false
						} else {
							let res = {}
							res[`${key}Start`] = this.start
							res[`${key}End`] = this.end
							this.$emit('editSelectVal', res)
						}
					} else {
						let res = {}
						res[`${key}Start`] = ''
						res[`${key}End`] = ''
						this.$emit('editSelectVal', res)
					}
				} else {
					let res = {}
					res[`${key}Start`] = ''
					res[`${key}End`] = ''
					this.$emit('editSelectVal', res)
				}
			},
			selectEdit(e) {
				let key = this.prop.replace('Code', '')
				let res = {}
				if (e) {
					this.editSelect = true
					res[`${key}Start`] = this.start
					res[`${key}End`] = this.end
				} else {
					res[`${key}Start`] = ''
					res[`${key}End`] = ''
					this.editSelect = false
				}
				this.$emit('editSelectVal', res)
			},
			//重置
			reSet() {
				this.start = ''
				this.end = ''
				this.SelectedCode = []
				this.checkAll = false
				this.indeterminate = false
				this.editSelect = false
				this.$emit('selectValue', this.prop, this.SelectedCode)
			},
			//设置选择内容
			setValue(data) {
				this.SelectedCode = data
				this.checkAllGroupChange(data)
			},
			//设置输入内容
			setInputValue(key, value) {
				this[key] = value
				this.editSelect = true
			}
		},
	}
</script>

<style scoped>

</style>
