<template>
	<div class="MySearch">
		<Form ref="MySearch" label-position='right' :label-width='100'>
			<FormItem v-show='searchTemplateList.length != 0' label='我的查询组' prop="MySearchItem">
				<Tag size='large' @on-change='selectSearch' checkable :key='index' :checked='item.checked'
					v-for="(item,index) in searchTemplateList" color="default" :name='item.id' closable
					@on-close='closeSerch'>
					<span style="font-size: 16px;cursor: pointer;">{{item.templateName}}</span>
				</Tag>
			</FormItem>
		</Form>
	</div>
</template>

<script>
	import {
		land_data_is_del_template
	} from '../../../../../api/reLand.js'
	export default {
		name: 'MySearch',
		props: {
			searchTemplateList: {
				type: Array,
				default: () => {
					return []
				}
			},
		},
		data() {
			return {
				timer: null
			}
		},
		methods: {
			//选择
			selectSearch(checked, name) {
				if (this.timer) {
					clearTimeout(this.timer)
				}
				this.timer = setTimeout(() => {
					this.$emit('setSearch', false)
					if (checked) {
						this.searchTemplateList.forEach(item => {
							if (item.id == name) {
								item.checked = true
								this.$emit('setSearch', JSON.parse(item.params))
							} else {
								item.checked = false
							}
						})
					}
				}, 500)
			},
			//删除
			async closeSerch(event, name) {
				let data = await land_data_is_del_template({
					id:name,
					isDel:1
				})
				if(data){
					this.$msg.success({
						text: "删除成功"
					})
				}
				this.$parent.$parent.getSearchTemplate()
			}

		},
	}
</script>

<style scoped lang="scss">
	.MySearch {
		width: 100%;
	}
</style>
