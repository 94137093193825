<template>
	<div class="SearchResults">
		<IndexPanel ref='IndexPanel' :headerOptions='showIndex' @sureHeader='getSelectedHeader'></IndexPanel>
		<DownloadDataBtn :style="{
        position: 'relative',
		float:'right',
        'margin-bottom': '20px',
      }" @exportData="exportData" :id="3" :type="1"></DownloadDataBtn>
		<Table max-height="750" style="margin-top: 30px;" :columns="headers" :data="data" no-data-text='暂无数据' disabled-hover>
			<template slot-scope="{ row }" slot="action">
				<span :class="['start',row.isAttention?'active':'']" @click="attention(row)"
					:title="row.isAttention?'取消关注':'关注地块'"></span>
			</template>
			<template slot-scope="{ row }" slot="landName">
				<Button type="text" @click="goLandDetail(row)">
					<span
						style="width: 20em;display:inline-block;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
						:title="row.landName">{{row.landName}}</span>
				</Button>
			</template>
		</Table>
		<div style="text-align: right; margin-top: 5px">
			<Page :total="total" show-elevator show-sizer @on-change="pageChange" @on-page-size-change="pageSizeChange" :page-size-opts='pageSizeOpts' class-name="pageStyle" />
		</div>


<!-- 		<ComfirmButton :customerStyle='customerStyle' ref='ComfirmButton' size='small' @comfirm='attention()' info=''>
			<div slot="content">
				<div class="nomalBox">
					<p class="text">{{row.isAttention ?'确认取消关注':'确认关注' }}</p>
				</div>
			</div>
		</ComfirmButton> -->


	</div>
</template>

<script>
	import IndexPanel from '../../../components/indexPanel/index.vue'
	import Headers from './headerOptions.js'
	import DownloadDataBtn from "@/components/DownloadDataBtn";
	// import ComfirmButton from '../../../../../components/Comfirm.vue'
	import {
		land_data_is_cancel_favorite, land_data_pageList
	} from '../../../../../api/reLand.js'
import { downloadData, formatJson } from '../../../../../utils';
import util from '../../../../../utils/utils2';
	export default {
		name: 'SearchResults',
		components: {
			IndexPanel,
			DownloadDataBtn
			// ComfirmButton
		},
		props: {
			showTra: {
				type: Boolean,
				default: false
			},
			landData: {
				type: Array,
				default: () => {
					return []
				}
			},
			total: {
				type: Number,
				default: 1
			},
			searchForm: {
				type: Object,
				default: () => {
					return {}
				}
			}
		},
		watch: {
			showTra: {
				handler(val) {
					if (val) {
						this.showIndex = Headers
					} else {
						this.showIndex = {}
						for (let key in Headers) {
							if (key != 'deal') {
								this.showIndex[key] = Headers[key]
							}
						}
						this.$nextTick(() => {
							delete this.$refs.IndexPanel.selectedHeader.deal
						})
					}
				},
				immediate: true
			},
			landData: {
				handler(val) {
					this.data = val
				},
				deep: true,
				immediate: true
			}
		},
		data() {
			return {
				showIndex: {},
				headers: [],
				data: [],
				row: {},
				customerStyle: {
					'opacity': 0
				},
				pageSizeOpts: [10, 20, 50, 100, 200],
			}
		},
		methods: {
			getSelectedHeader(res) {
				this.headers = [{
					title: '操作',
					slot: 'action',
					align: 'center',
					fixed:'left',
					minWidth: 200
				}, {
					title: '地块名称',
					key: 'landName',
					align: 'center',
					fixed:'left',
					slot: 'landName',
					minWidth: 320
				}, ]
				let Arr = []
				for (let key in res) {
					Arr = Arr.concat(res[key])
				}
				this.headers = this.headers.concat(Arr)
			},
			//关注
			async attention(row) {
				row.isCancel = row.isAttention
				row.isAttention = !row.isAttention
				let data = await land_data_is_cancel_favorite(row)
				if (data) {
					this.$msg.success({
						text: row.isAttention ? '关注成功' : '取消关注成功'
					})
				}
			},
			showComfirm(row) {
				this.row = row
				this.$refs.ComfirmButton.toggle()
			},
			//跳转地块详情
			goLandDetail(row) {
				let {
					landRelationKey,
					landPosition,
					transactionStateCode
				} = row

				this.$router.push({
					path: '/Insight/land/landDetail',
					query: {
						landRelationKey: landRelationKey,
						landPosition: landPosition,
						transactionStateCode: transactionStateCode,
					}
				})
			},
			//页码改变
			pageChange(e){
				this.$emit('pageChange',e)
			},
			//页大小改变
			pageSizeChange(e){
				this.$emit('pageSizeChange',e)
			},
			exportData() {
				const params = this.$parent.$refs.SearchFormValue.SearchForm
				params.cityCode = this.$parent.$refs.SearchFormValue.SearchForm.city.cityCode
				params.pageSize = 100000000;
				params.current = 1;
				// conditionSearch(params).then((data) => {
				// 	const tableData = data.records;
				// 	let filterVal = [];
				// 	let tHeader = [];
				// 	this.tableColumns.forEach((item) => {
				// 		tHeader.push(item.title);
				// 		filterVal.push(item.key);
				// 	});
				// 	const resData = formatJson(filterVal, tableData);
				// 	downloadData(tHeader, resData, "条件搜索");
				// });
				land_data_pageList(params).then( data =>{
					data.records.forEach((item)=>{
						item.transferDate = item.transferDate ? util.formatDate(new Date(item.transferDate),"yyyy-MM-dd") : ''
						item.transactionDate = item.transactionDate? util.formatDate(new Date(item.transactionDate),"yyyy-MM-dd"): ''
						item.expirationDate = item.expirationDate? util.formatDate(new Date(item.expirationDate),"yyyy-MM-dd") : ''
					})
					const tableData = data.records
					let filterVal = [];
					let tHeader = [];
					this.headers.forEach((item) => {
						if(item.key){
							tHeader.push(item.title);
							filterVal.push(item.key);
						}
					});
					const resData = formatJson(filterVal, tableData)
					downloadData(tHeader, resData, "列表搜索");


				})
			},
			getSearchData() {
				return this.$parent.$refs.SearchFormValue.SearchForm
			}
		},

	}
</script>

<style scoped lang="scss">
	.SearchResults {
		margin-top: 30px;

	}

	.start {
		cursor: pointer;
		display: inline-block;
		width: 20px;
		height: 20px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: auto;
		background-image: url(../../../../../assets/image/land/start.png);

		&.active {
			background-image: url(../../../../../assets/image/land/start_active.png);
		}
	}

	.text {
		text-align: center;
		font-size: 18px;
		font-weight: 500;
		padding-top: 50px;
		padding-bottom: 30px;
	}
	.pageStyle{
		position: relative;
	}
</style>
