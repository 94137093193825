<template>
	<div class="citySelect" @click.stop>
		<Dropdown trigger="custom" :transfer="isTransfer" placement='bottom-start' :visible="visible"
			@on-visible-change='visibleChange'>
			<Button style="width: 150px;z-index:2" size='large' type="default" @click="handleOpen">
				{{ctiy.cityName}}
				<Icon :type="iconType" />
			</Button>
			<div slot="list" @click.stop>
				<div class="citySelectBox">
					<div class="queryInput">
						<Select filterable filter-by-label clearable @on-select='selectSearch'>
							<Option v-for="item in allCityList" :value="item.cityCode" :key="item.cityCode"
								:label="item.cityName">{{ item.cityName}}
							</Option>
						</Select>
					</div>
					<div class="hotCity">
						<CityListItem :ListObj='hotList' @selectArea='selectArea'></CityListItem>
					</div>
					<div class="cityListBox" @wheel.stop>
						<div class="cityList">
							<CityListItem :key='index' v-for="(item,index) in provinceList" :ListObj='item'
								@selectArea='selectArea' :canSelectProvince='canSelectProvince'></CityListItem>
						</div>

					</div>
				</div>
			</div>
		</Dropdown>
	</div>
</template>

<script>
	import CityListItem from './CityListItem.vue'
	import {
		land_data_area
	} from '../../../../api/reLand.js'
	export default {
		name: 'citySelect',
		components: {
			CityListItem
		},
		props: {
			ctiy: {
				type: Object,
				default: () => {
					return {}
				}
			},
			canSelectProvince:{
				type: Boolean,
				default: true
			},
			showQuanGuo: {
				type: Boolean,
				default: true
			},
			isTransfer: {
				type: Boolean,
				default: true
			}
		},
		data() {
			return {
				query: '',
				iconType: 'md-arrow-dropdown',
				hotList: {},
				provinceList: [],
				allCityList: [],
				visible: false,
			}
		},
		deactivated(){
			this.handleClose()
		},
		mounted() {
			document.addEventListener("click", this.bodyCloseMenuse);
		},
		beforeDestroy() {
			document.removeEventListener("click", this.bodyCloseMenuse);
		},
		methods: {
			async init(e) {
				if (e) {
					let data = await land_data_area()
					this.hotList = this.setHotList(data.hotCityList)
					this.provinceList = this.setProvince(data.provinceList)
					this.allCityList = this.setAllCityList(data.allCityList)
				}
			},
			handleOpen() {
				this.visible = !this.visible
			},
			handleClose() {
				this.visible = false
			},

			//下拉列表打开OR关闭
			visibleChange(e) {
				this.init(e)
				if (e) {
					this.iconType = 'md-arrow-dropup'
				} else {
					this.iconType = 'md-arrow-dropdown'
				}
			},
			selectArea(area,areaType) {
				let AREA = JSON.parse(JSON.stringify(area))
				if(!this.canSelectProvince && areaType == 'province'){
					return
				}
				this.handleClose()
				this.$emit('selectArea', AREA)
			},
			setHotList(arr) {
				let obj = {}
				obj.area = '热门'
				obj.type = 'hot'
				// obj.list = arr.slice(1) //去除全国选项
				obj.list = this.showQuanGuo ? arr : arr.slice(1)
				return obj
			},
			setProvince(arr) {
				let ARR = []
				arr.forEach(item => {
					let obj = {}
					obj.area = item.province
					obj.type = 'noHot'
					obj.list = item.cityList
					obj.zoom = item.zoom
					obj.provinceName = item.provinceName
					ARR.push(obj)
				})
				return ARR
			},
			setAllCityList(arr) {
				return arr
			},
			selectSearch(city){
				let selectedCity = this.allCityList.filter(item => {
					return item.cityCode == city.value
				})
				this.selectArea(selectedCity[0])
			},
			bodyCloseMenuse(e) {
				if(this.visible === true){
					this.visible = false
				}
			}
		},
	}
</script>

<style lang="scss">
	.citySelectBox {
		padding: 20px;

		.queryInput {
			padding-bottom: 20px;
			border-bottom: #999999 solid 1px;
		}

		.hotCity {
			margin-top: 20px;
		}

		.cityListBox {
			height: 350px;
			overflow: scroll;

			.cityList {}
		}
	}

	.ivu-select-dropdown {
		max-height: 500px !important;
		overflow: auto !important;
	}
</style>
